<template>
  <div class="radio-input" :style="style">
    <BaseCard :base-card="baseCard" class="h-100">
      <FormulateInput
        :options="options"
        type="radio"
        :label="label"
        :value="value"
        @input="inputChanged"
      />
    </BaseCard>
  </div>
</template>

<script>
import BaseCard from "@/components/Cards/BaseCard";

export default {
  name: "RadioInput",
  components: {
    BaseCard,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    fontFamily: {
      type: String,
      default: "inherit",
    },
    fontWeight: {
      type: String,
      default: "400",
    },
    fontSize: {
      type: String,
      default: "inherit",
    },
    baseCard: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      style: {
        "--font-family": this.fontFamily,
        "--font-weight": this.fontWeight,
        "--font-size": this.fontSize,
      },
    };
  },
  methods: {
    inputChanged(value) {
      this.$emit("changed", value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
::v-deep > .formulate-input {
  margin-bottom: 0;
  flex: 0 0 50%;

  @media (max-width: 767px) {
    flex: 0 0 100%;
  }

  @media (min-width: 992px) and (max-width: 1600px) {
    flex: 0 0 100%;
  }

  .formulate-input-wrapper {
    display: flex;
    align-items: center;

    .formulate-input-label {
      font-family: var(--font-family);
      font-weight: var(--font-weight);
      margin-right: 1rem;
      font-size: var(--font-size);
      margin-bottom: 4px;
    }

    .formulate-input-element {
      flex-grow: 1;
      max-width: 100%;
      height: 38px;

      &.formulate-input-group {
        display: flex;
        justify-content: flex-start;
        gap: 25px;
        margin-right: 25px;

        .formulate-input-group-item {
          margin-bottom: 0;
        }

        .formulate-input-wrapper {
          label {
            font-family: "Oswald", sans-serif;
            border-color: $purple;
          }

          input {
            &[type="radio"]:checked {
              & ~ label {
                &::before {
                  background-color: $purple;
                }
              }
            }
          }
        }
      }
    }
  }

  &[data-classification="select"] {
    select {
      &:focus {
        border-color: $purple;
      }
    }
  }

  label {
    margin-bottom: 0;
  }
}
</style>
