<template>
  <BaseCard class="selectable-dropdown">
    <FormulateInput
      type="radio"
      v-model="checkbox.value"
      :options="checkbox.list"
      :placeholder="checkbox.placeholder"
      :label="checkbox.label"
      @input="checkboxChanged"
    />
    <FormulateInput
      type="select"
      v-model="dropdown.value"
      :options="dropdown.list"
      :placeholder="dropdown.placeholder"
      :label="dropdown.label"
      @input="dropdownChanged"
    />
  </BaseCard>
</template>

<script>
import BaseCard from "@/components/Cards/BaseCard";

export default {
  name: "SelectableDropdown",
  components: {
    BaseCard,
  },
  props: {
    dropdown: {
      type: Object,
      default: () => {},
    },
    checkbox: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    dropdownChanged(value) {
      this.$emit("dropdown-changed", value);
    },
    checkboxChanged(value) {
      this.$emit("checkbox-changed", value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.selectable-dropdown {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  ::v-deep > .formulate-input {
    margin-bottom: 0;
    flex: 0 0 50%;

    @media (max-width: 767px) {
      flex: 0 0 100%;
    }

    @media (min-width: 992px) and (max-width: 1600px) {
      flex: 0 0 100%;
    }

    .formulate-input-element {
      max-width: 100%;
      height: 38px;

      &.formulate-input-group {
        display: flex;
        justify-content: flex-start;
        gap: 25px;
        margin-right: 25px;

        .formulate-input-group-item {
          margin-bottom: 0;
        }

        .formulate-input-wrapper {
          label {
            font-family: "Oswald", sans-serif;
            border-color: $purple;
          }

          input {
            &[type="radio"]:checked {
              & ~ label {
                &::before {
                  background-color: $purple;
                }
              }
            }
          }
        }
      }
    }

    &[data-classification="select"] {
      select {
        &:focus {
          border-color: $purple;
        }
      }
    }

    label {
      margin-bottom: 0;
    }
  }
}
</style>
