<template>
    <div v-if="localMBData.requestDone" class="basic">
        <div class="row">
            <div class="col-lg-6 mb-4">
                <LabelInput
                    class="mb-4"
                    font-family="'Oswald', sans-serif"
                    :base-card="true"
                    :label="labels.title"
                    :value="localMBData.title"
                    @changed="dataChanged({ value: $event, key: 'title' })"
                />
                <TextEditorCard
                    :title="labels.description"
                    :value="localMBData.description"
                    @changed="dataChanged({ value: $event.value, key: 'description' })"
                />
            </div>
            <div class="pricing col-lg-6 mb-4">
                <LabelInput
                    v-if="features.payable"
                    class="mb-4"
                    font-family="'Oswald', sans-serif"
                    :label="labels.pricing"
                    :base-card="true"
                    :data="labelInputData"
                />
                <SelectableDropdown
                    v-if="features.payable"
                    class="mb-4"
                    :dropdown="dropdownData"
                    :checkbox="checkboxData"
                    @checkbox-changed="() => {}"
                    @dropdown-changed="() => {}"
                />
                <AssignInput
                    :title="labels.groups"
                    :assign-list="localMBData.groups"
                    :show-add-btn="true"
                    :add-btn-label="labels.add"
                />
                <RadioInput
                    class="mt-4"
                    :options="statuses"
                    :label="labels.status"
                    :base-card="true"
                    :value="localMBData.status"
                    @changed="dataChanged({ value: parseInt( $event ), key: 'status' })"
                />
                <ArchiveCard :labels="labels" :title="localMBData.title" @archive="archiveMBundle" :type="labels['monetization_bundle']"/>
            </div>
        </div>
    </div>
</template>

<script>
import LabelInput from "@/components/Input/LabelInput";
import TextEditorCard from "@/components/Input/TextEditorCard";
import SelectableDropdown from "@/components/Dropdowns/SelectableDropdown";
import AssignInput from "@/components/Input/AssignInput";
import RadioInput from "@/components/Input/RadioInput";
import ArchiveCard from "@/components/Cards/ArchiveCard";

import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';

export default {
    name: "Basic",
    components: {
        LabelInput,
        TextEditorCard,
        SelectableDropdown,
        AssignInput,
        RadioInput,
        ArchiveCard
    },
    data() {
        return {
            labelInputData: {
                label: "Price",
                value: "",
            },
            dropdownData: {
                value: "",
                placeholder: "Select option",
                label: "",
                list: { first: "First", second: "Second", third: "Third", fourth: "Fourth" },
            },
            checkboxData: {
                value: "first",
                placeholder: "",
                list: { first: "Single", second: "Multiple" },
            },
            status: 0
        };
    },
    computed: {
        ...mapState( 'monetizationBundle', {
            singleData: 'singleData',
        }),

        ...mapState( 'helper', ['features'] ),
        ...mapGetters('helper', ['labels']),
        localMBData() {
            return this.$store.state.monetizationBundle.localMBData;
        },
        ...mapState( 'monetizationBundle', ['localMBData'] ),
        statuses() {
            return {
                0: this.labels.coming_soon,
                1: this.labels.beta,
                2: this.labels.live
            };
        }
    },
    methods: {
		...mapMutations('monetizationBundle', ['updateLocalMBDataString']),
        ...mapActions('notification', ['addNotification']),
        dataChanged( data ) {
            this.updateLocalMBDataString({key: data.key, value: data.value});
            //this.$emit('bundleBasicChanged', data.key, data.value);
		},
        archiveMBundle() {
            this.$store.dispatch('monetizationBundle/archive', { id: this.$route.params.id }).then(res=>{
                if(res.success){
                    this.addNotification({
                        variant: "success",
                        msg: `${this.localMBData.title} ${this.labels.archived.toLowerCase()}`
                    });

                    this.$router.push({ name: 'monetization-bundle-overview'})
                } else {
                    this.addNotification({
                        variant: "danger",
                        msg: res.error,
                        labels: 'res.error'
                    });
                }
            })
        }
    }
};
</script>

<style lang="scss" scoped>
    ::v-deep {
        .formulate-input-element-decorator {
            margin-bottom: 0;
        }
    }
</style>
