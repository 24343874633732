<template>
  <BaseCard class="assign" :base-card="baseCard">
    <div class="assign__title col-2" v-if="title">{{ title }}</div>

    <div
      class="input-wrapper col-10"
      :style="styles"
      :class="{ active: assignList.length }"
    >
      <div class="assign__item" v-for="(item, key) in assignList" :key="key">
        <span class="assign__item-number">{{ item }}</span>
        <span v-if="enabled">
          <font-awesome-icon icon="times" @click="deleteElement(key)" />
        </span>
      </div>
      <div class="assign__input">
        <FormulateInput
          :class="{ active: assignList.length }"
          :type="inputType"
          :name="inputType"
          v-model="value"
          :placeholder="placeholder"
          @keyup.enter="addElement"
          :disabled="!enabled"
          :label="label"
        />
      </div>

      <Button v-if="showAddBtn && value" :bg-color="configColors.green" :color="configColors.white" padding="5px 20px" @click="addElement">
        {{ addBtnLabel }}
      </Button>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "@/components/Cards/BaseCard";
import Button from "@/components/Buttons/Button";
const colors = require("/colors.config.json");
export default {
  name: "AssignInput",
  components: {
    BaseCard,
    Button
  },
  data() {
    return {
      value: "",
      styles: {
        "--item-color": this.itemColor,
        "--item-background": this.itemBackground,
        "--item-padding": this.itemPadding,
      },
      configColors: colors
    };
  },
  props: {
    baseCard: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    assignList: {
      type: Array,
      default: () => [],
    },
    inputType: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
    },
    itemPadding: {
      type: String,
      default: "5px 5px 5px 10px",
    },
    itemBackground: {
      type: String,
      default: `${colors.background_3}`,
    },
    itemColor: {
      type: String,
      default: `${colors.dark_2}`,
    },
    enabled: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "",
    },
    showAddBtn: {
      type: Boolean,
      default: false
    },
    addBtnLabel: {
      type: String,
      default: ''
    }
  },
  methods: {
    addElement() {
      this.assignList.push(this.value);
      this.changed(this.value, "add");
      this.value = "";
      this.errors = [];
    },
    deleteElement(key) {
      this.changed(this.assignList[key], "delete");
      this.assignList.splice(key, 1);
    },
    changed(value, status) {
      this.$emit("changed", { value, status });
    },
  }
};
</script>

<style lang="scss" scoped>
.assign {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Oswald", sans-serif;

  &__title {
    margin-right: 25px;
  }

  .input-wrapper {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    border-radius: 0.3em;
    border: 1px solid $light_gray;
    position: relative;
    margin-bottom: 0;
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: var(--item-padding);
    color: var(--item-color);
    background-color: var(--item-background);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin: 0.2em;

    &-number {
      margin-right: 15px;
    }
    span:last-child {
      position: relative;
      display: flex;
      align-items: center;
    }

    &:last-child {
      margin-right: 0;
    }

    svg {
      padding: 3px;
      color: $background_3;
      background-color: $light_text;
      height: 20px;
      width: 20px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  &__input {
    flex: 1 1 auto;
    margin: 0.2em;

    ::v-deep .formulate-input {
      height: 100%;

      .formulate-input-wrapper {
        height: 100%;

        .formulate-input-element {
          margin-bottom: 0;
          height: 100%;
          max-width: 100%;

          input {
            border: none;
            height: 100%;
          }
        }
      }
    }

    ::v-deep .formulate-input-wrapper {
      position: relative;
    }

    ::v-deep label {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      background: transparent;
      padding: 0 5px;
      z-index: 1;
      cursor: text;
      transition: all 0.2s ease;
      color: #a8b0b1;
      font-size: 16px;
      font-weight: 400;
      line-height: 100%;
      margin-bottom: 0;
    }
  }
}
</style>
